import React from 'react';
import './Footer.css';
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import logo from './images/logo.png';
import { MdPhoneInTalk } from "react-icons/md";
import { FaFacebookF, FaYoutube, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section contact">
          <h2>Contact Information</h2>
          <p>
            <a className='addinfo' href='https://maps.app.goo.gl/JQ6nhxqMAx1g3oiB6'>
              <FaMapMarkerAlt />Khushi Hospital
              35, Pratap Nagar, Jalgaon - 425001, Maharashtra, India
            </a>
          </p>
          <p><FaEnvelope /> khushihospital2018@gmail.com<br />khushihosp.cashless@gmail.com</p>
          <p><MdPhoneInTalk /> +91 92 25306774 </p>
          <p><MdPhoneInTalk />+91 99 21395600</p>
        </div>

        <div className='footer-section quick-links'>
          <h2>Quick Links</h2>
          <ul>
            <li><a href='/' className='link'>Home</a></li>
            <li><a href='/service' className='link'>Services</a></li>
            <li><a href='/eform' className='link'>Enquire Us</a></li>
            <li><a href='/aboutus' className='link'>About Us</a></li>
          </ul>
        </div>

        <div className='footer-section follow-us'>
          <h2>Follow Us</h2>
          <a href="https://www.facebook.com/profile.php?id=100083347366159" target="_blank" rel="noopener noreferrer" className="f"><FaFacebookF /></a>
              <a href="https://youtube.com/@sachininglecancerlaparoscopy?si=Y4d2edXMTvNIhK9m" target="_blank" rel="noopener noreferrer" className="f"><FaYoutube /></a>
              <a href="https://www.instagram.com/khushihospital12?igsh=MWtlbjczem13MnZibA==" target="_blank" rel="noopener noreferrer" className="f"><FaInstagram /></a>
              <a href="https://wa.me/919225306774" target="_blank" rel="noopener noreferrer" className="f"><FaWhatsapp /></a>
        </div>

        <div className='footer-section patient-care'>
          <h2>Patient Care</h2>
          <ul>
            <li>Request Appointment</li>
            <li>News & Events</li>
            <li>Patient Education</li>
            <li>Cost Of Treatment</li>
            <li>Health Info</li>
            <li>Video Gallery</li>
            <li>Second opinion</li>
            <li>Drugs & Supplements</li>
            <li>Tests & Procedures</li>
          </ul>
        </div>

        <div className='footer-section research'>
          <h2>Research</h2>
          <ul>
            <li>Explore Research Areas</li>
            <li>Find Clinical Trials</li>
            <li>Research Faculty</li>
          </ul>
        </div>
      </div>

      <div className="highlight-section">
        <p>All Cashless Available</p>
        <p>24X7 Emergency Care</p>
        <p>Mukhyamantri Sahaytanidhi Seva Available</p>
      </div>

      <div className="copyright-section">
        <p>&copy; 2024-2025 Khushi Hospital. 35, Pratap Nagar, Jalgaon - 425001, Maharashtra, India. All Rights Reserved.</p>
        <p>Khushi Hospital: Expertise in Cancer & Laparoscopic surgery in Jalgaon, India</p>
        <p>All Rights Reserved By <a className='marketingagency' href='https://wa.link/53j56t'>Shree Digital Marketing Agency & Dipali Marathe</a>.</p>
      </div>
    </footer>
  );
}

export default Footer;
